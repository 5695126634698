export const BigBar: React.FC<
  React.PropsWithChildren<{
    title: string | string[];
    comment?: string;
  }>
> = ({ title, comment = '', children }) => (
  <div className="tw-relative tw-min-h-[12rem] tw-w-full tw-pb-[2rem] tw-pt-[4rem]">
    <div
      className="tw-absolute tw-inset-0 tw-bg-main-100 tw-bg-cover tw-bg-[top_center] tw-bg-no-repeat tablet:tw-bg-[800px_auto]"
      style={{
        backgroundImage: 'url(/images/bigbar_background.svg)',
      }}
    />
    <div className="tw-custom-side-space tw-relative tw-mx-auto tw-max-w-[45rem]">
      <h1 className="tw-break-keep tw-text-h2 tw-text-base-100">
        {Array.isArray(title)
          ? title.map((value) => (
              <span key={value} className="tw-inline-block">
                {value}
              </span>
            ))
          : title}
      </h1>
      {comment && (
        <p className="tw-mt-[0.5rem] tw-text-base2 tw-leading-[1.6] tw-text-base-100">
          {comment}
        </p>
      )}
      {children && <div className="tw-mt-[1rem]">{children}</div>}
    </div>
  </div>
);
