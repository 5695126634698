type CategoryItemData = {
  name: string;
  text: string;
  relationId: string;
};

export const CATEGORY_MAP: {
  [key: string]: CategoryItemData;
} = {
  c001: {
    name: '申し込み',
    text: '各種サービス、オプションのお申込み方法について',
    relationId: 'Wjexmh4AAGkuCrlk',
  },
  c002: {
    name: '契約内容の確認・変更・解約',
    text: '各種サービスの契約・解約方法について',
    relationId: 'Wjexrh4AAGUuCrnB',
  },
  c003: {
    name: '支払い状況の確認・変更',
    text: '支払方法の確認・変更、購入明細の確認について',
    relationId: 'WjexwR4AAGUuCrod',
  },
  c005: {
    name: 'アカウント/ファミリーアカウント',
    text: 'アカウントの追加や設定方法について',
    relationId: 'WjelSh4AAGUuCoK9',
  },
  c006: {
    name: 'ログインID/パスワード',
    text: 'ログインID／パスワードの変更・トラブルについて',
    relationId: 'Wjee7R4AAM0rCmaV',
  },
  c007: {
    name: '再生',
    text: '再生できないなどのトラブル解決法について',
    relationId: 'Wjevzx4AAF8vCrFO',
  },
  c008: {
    name: 'コンテンツ',
    text: '視聴履歴・マイリストなどの確認方法について',
    relationId: 'WjewFh4AAGUuCrKK',
  },
  c009: {
    name: 'ダウンロード',
    text: 'とっても便利なダウンロード機能について',
    relationId: 'Wjew5R4AAGUuCrYg',
  },
  c010: {
    name: 'キャスト',
    text: 'スマホ、タブレットをテレビに出力する方法について',
    relationId: 'WjexHx4AAGkuCrcs',
  },
  c011: {
    name: 'ポイント・Uコイン',
    text: 'チャージ方法や確認、利用方法について',
    relationId: 'WjexNh4AACItCreU',
  },
  c012: {
    name: 'ギフトコード',
    text: '入力方法やエラー時の対応について',
    relationId: 'WjexSx4AAGkuCrf6',
  },
  c013: {
    name: 'メール',
    text: 'メールアドレスの変更・設定について',
    relationId: 'WjexXx4AAGUuCrhU',
  },
  c014: {
    name: 'セキュリティコード',
    text: '変更や紛失時の対応について',
    relationId: 'Wjexch4AAF8vCriq',
  },
  c015: {
    name: 'ペアレンタルロック',
    text: '子アカウントの視聴制限について',
    relationId: 'Wjexhx4AACItCrkK',
  },
  c018: {
    name: 'ブックサービス',
    text: '利用方法やトラブル解決方法について',
    relationId: 'Wjex1R4AACItCrp1',
  },
  c019: {
    name: '「SMART USEN」について',
    text: '利用方法やトラブル解決方法について',
    relationId: 'Wjex7B4AAGkuCrrh',
  },
  c020: {
    name: '「U-NEXT TV」について',
    text: '接続方法やトラブル時の対応法について',
    relationId: 'WjexCx4AAGkuCrbU',
  },
  c021: {
    name: '各種設定',
    text: '再生に関する各種設定について',
    relationId: 'Wjev8h4AAGUuCrHs',
  },
  c022: {
    name: '検索',
    text: '作品検索方法や絞り込みについて',
    relationId: 'Wjewzx4AAGUuCrW8',
  },
  c023: {
    name: 'NHKオンデマンド',
    text: '対象作品やトラブル対応方法について',
    relationId: 'Wjew-R4AACItCraA',
  },
  c024: {
    name: '映画館クーポン',
    text: '映画館クーポンについて',
    relationId: 'WyM5EScAAI2Zhggq',
  },
  c031: {
    name: 'スケジュール・仕様',
    text: 'スケジュールやライブ配信の詳細について',
    relationId: 'X4_nDxQAACQAK_ng',
  },
  c032: {
    name: '対応デバイス',
    text: '対応しているデバイスとテレビでの視聴について',
    relationId: 'X4_ztBQAACMALDNz',
  },
  c033: {
    name: '購入',
    text: '購入方法や支払い方法、購入済のチケットの確認方法について',
    relationId: 'X4_2ZxQAACQALD-m',
  },
  c034: {
    name: '再生',
    text: '再生方法や視聴中のトラブルへの対処について',
    relationId: 'X5AHsRQAACUALI0C',
  },
  c035: {
    name: 'その他',
    text: 'ライブ配信に関するその他の事項',
    relationId: 'X5AIBxQAACUALI5_',
  },
  c036: {
    name: 'サッカーパック',
    text: '対象作品やトラブル対応方法について',
    relationId: 'ZqcEfhEAAC0AJ5hj',
  },

  // U-NEXTについて
  fc001: {
    name: '映像作品',
    text: '基本的な用語説明や利用方法について',
    relationId: 'Wje6mh4AAF8vCuEz',
  },
  fc002: {
    name: '雑誌',
    text: '詳細な利用方法について',
    relationId: 'Wje6rR4AAGUuCuGM',
  },
  fc003: {
    name: 'コミック・書籍',
    text: '詳細な利用方法について',
    relationId: 'Wje6vx4AACItCuHk',
  },
  fc004: {
    name: 'ポイント',
    text: 'ポイントのお得な活用術について',
    relationId: 'Wje60B4AACItCuI2',
  },
  fc005: {
    name: 'ファミリーアカウント',
    text: '利用時の利点や設定方法について',
    relationId: 'Wje64R4AAGkuCuKK',
  },
  fc006: {
    name: 'マルチデバイス',
    text: '対応デバイスやデバイス間の同期について',
    relationId: 'Wje69B4AAGUuCuLh',
  },
  fc007: {
    name: 'ダウンロード',
    text: '機能の説明と、利用方法について',
    relationId: 'Wje7BB4AACItCuMr',
  },
  fc008: {
    name: '高画質・高音質',
    text: '対応画質と利用方法について',
    relationId: 'Wje7FR4AACItCuN8',
  },
  fc009: {
    name: '特集',
    text: '作品選びを楽しくするご提案について',
    relationId: 'Wje7JR4AAGUuCuPM',
  },
  fc010: {
    name: '音楽サービス',
    text: 'サービス内容と利用方法について',
    relationId: 'Wje7ax4AACItCuUS',
  },
  fc011: {
    name: '料金',
    text: '支払い方法や決済方法について',
    relationId: 'Wje7OB4AACItCuQh',
  },
  fc012: {
    name: '無料トライアル',
    text: 'トライアルキャンペーン詳細について',
    relationId: 'Wje7SB4AAF8vCuRr',
  },
  fc013: {
    name: '解約',
    text: '解約方法や再開方法について',
    relationId: 'Wje7WR4AAGUuCuS9',
  },

  // paravi
  pc001: {
    name: 'Paraviの移行手続き・利用開始する方法',
    text: '移行手続き・利用開始に関する説明',
    relationId: 'ZIwDNBEAACEA5qk1',
  },
  pc002: {
    name: 'Paraviベーシックプランの確認・変更・解約',
    text: '利用中プランの確認や解約に関する説明',
    relationId: 'ZIwDXhEAACEA5qns',
  },
  pc003: {
    name: 'Paraviベーシックプランの支払いに関する確認・変更',
    text: '料金の確認や支払い方法に関する説明',
    relationId: 'ZIwDgBEAACQA5qqJ',
  },
  pc004: {
    name: 'Paravi作品の探し方・視聴方法',
    text: 'Paravi作品の探し方や、視聴に関する説明',
    relationId: 'ZIwDtxEAACMA5qt7',
  },
};

const GROUP_TYPE = {
  account: 'account',
  howTo: 'howTo',
  aboutUs: 'aboutUs',
} as const;

export type GroupItemData = {
  code: string;
  name: string;
  text: string;
  category: string[];
  type: (typeof GROUP_TYPE)[keyof typeof GROUP_TYPE];
};

export const GROUP_LIST: GroupItemData[] = [
  {
    code: 'g01',
    name: '各種お手続き',
    text: '各種お申込みや、契約・解約、支払い方法について',
    category: ['c001', 'c002', 'c003'],
    type: GROUP_TYPE.account,
  },
  {
    code: 'g08',
    name: 'U-NEXTについて',
    text: '初めてのお客さまに、サービスを詳しくご紹介',
    category: [
      'fc001',
      'fc002',
      'fc003',
      'fc004',
      'fc005',
      'fc006',
      'fc007',
      'fc008',
      'fc009',
      'fc010',
      'fc011',
      'fc012',
      'fc013',
    ],
    type: GROUP_TYPE.aboutUs,
  },
  {
    code: 'g03',
    name: 'アカウント',
    text: 'アカウントの有効活用術と管理方法について',
    category: ['c005', 'c006'],
    type: 'account',
  },
  {
    code: 'g04',
    name: '視聴関連',
    text: '動画作品を楽しむ際の設定やトラブル解決法について',
    category: [
      'c007',
      'c021',
      'c008',
      'c022',
      'c009',
      'c023',
      'c036',
      'c020',
      'c010',
    ],
    type: GROUP_TYPE.howTo,
  },
  {
    code: 'g09',
    name: 'ライブ配信',
    text: '生配信や収録ライブなど高画質・高音質のライブビューイングについて',
    category: ['c031', 'c032', 'c033', 'c034', 'c035'],
    type: GROUP_TYPE.howTo,
  },
  {
    code: 'g06',
    name: '設定',
    text: 'メール、セキュリティコードなどの設定・変更について',
    category: ['c013', 'c014', 'c015'],
    type: GROUP_TYPE.account,
  },
  {
    code: 'g05',
    name: 'チャージ・ギフトコード',
    text: 'ポイント・Uコインのチャージ方法や残ポイントの確認、支払い方法について',
    category: ['c011', 'c012'],
    type: GROUP_TYPE.account,
  },
  {
    code: 'g07',
    name: 'その他',
    text: '雑誌やコミック・書籍、音楽などの各種サービスについて',
    category: ['c018', 'c019', 'c024'],
    type: GROUP_TYPE.howTo,
  },
  {
    code: 'g10',
    name: 'Paraviベーシックプランについて',
    text: 'Paraviアカウントから移行された方への各種説明',
    category: ['pc001', 'pc002', 'pc003', 'pc004'],
    type: GROUP_TYPE.aboutUs,
  },
];
