import Link from 'next/link';
import { Spacer } from '../../atoms/Spacer';

export type MenuItem = {
  url: string;
  preText?: string;
  title: string;
  text?: string | string[];
  isWarning?: boolean;
  isEmphasized?: boolean;
};

export const MenuListCard: React.FC<{
  preTitle?: string;
  title?: string;
  menus: MenuItem[];
}> = ({ title, preTitle, menus }) => (
  <section className="tw-custom-side-space tw-max-w-[45rem] tw-overflow-hidden tw-rounded-10 tw-border tw-border-solid tw-border-main-10 tw-bg-base-100">
    {title && (
      <div className="tw-border-b tw-border-solid tw-border-main-10 tw-px-[1rem] tw-py-[1.5rem] tablet:tw-px-[1.5rem]">
        {preTitle && (
          <>
            <div className="tw-text-base2">{preTitle}</div>
            <Spacer level={0.25} />
          </>
        )}
        <h2 className="tw-text-h4 tw-text-main-100">{title}</h2>
      </div>
    )}
    <ul>
      {menus.map(
        ({
          url,
          preText,
          title,
          text,
          isWarning = false,
          isEmphasized = false,
        }) => (
          <li
            key={url}
            className="tw-relative tw-border-t tw-border-solid tw-border-main-10 first:tw-border-t-[0px]"
          >
            <Link
              href={url}
              passHref
              className="tw-relative tw-block tw-px-[1rem] tw-py-[1.5rem] tw-transition-colors hover:tw-bg-main-5 tablet:tw-px-[1.5rem]"
            >
              <div className="tw-grid tw-grid-cols-[1fr,1rem]">
                <div>
                  {preText && (
                    <div>
                      <div className="tw-text-base2 tw-text-main-40">
                        {preText}
                      </div>
                      <Spacer level={0.25} />
                    </div>
                  )}
                  <h2
                    className={
                      isEmphasized ? 'tw-text-h5' : 'tw-text-base1Title'
                    }
                  >
                    <div
                      className={
                        isWarning
                          ? 'tw-text-caution-primary'
                          : 'tw-text-main-100'
                      }
                    >
                      {title}
                    </div>
                  </h2>
                  {text && (
                    <div>
                      <Spacer level={0.5} />
                      <div className="tw-text-base2">
                        {text instanceof Array ? (
                          <div>
                            {text.map((data) => (
                              <div key={data}>{data}</div>
                            ))}
                          </div>
                        ) : (
                          <div>{text}</div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="tw-relative tw-h-full tw-w-full">
                  <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-my-auto tw-h-[0.4rem] tw-w-[0.4rem] tw-rotate-[45deg] tw-border-r-[2px] tw-border-t-[2px] tw-border-solid tw-border-main-40" />
                </div>
              </div>
            </Link>
          </li>
        )
      )}
    </ul>
  </section>
);
