import { useRouter } from 'next/router';
import React from 'react';
import { BigBar } from '@/shared/components/organisms/BigBar';

export const BigBarWithSearchGuide: React.FC<
  React.ComponentProps<typeof BigBar>
> = (props) => {
  const router = useRouter();
  const [searchQuery, setSearchQuery] = React.useState<string>(
    router.query.q ? (router.query.q as string) : ''
  );

  const handleOnSubmit = () => {
    router.push(`/guide/search?q=${searchQuery}`);
  };

  return (
    <BigBar {...props}>
      <form
        action=""
        method="get"
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          handleOnSubmit();
        }}
      >
        <div className="tw-grid tw-h-[3.5rem] tw-grid-cols-[1fr,4rem] tw-overflow-hidden tw-rounded-10 tw-bg-base-100">
          <div>
            <input
              type="text"
              name="q"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchQuery(e.target.value);
              }}
              placeholder="例) 再生、パスワード、解約方法"
              className="tw-h-full tw-w-full tw-border-none tw-px-[1.5rem] tw-outline-none"
            />
          </div>
          <div>
            <button
              className="tw-relative tw-h-full tw-w-[4rem] tw-overflow-hidden tw-bg-accent-primary"
              type="submit"
            >
              <img
                src="/images/guide/icon_search_white.svg"
                alt=""
                className="tw-absolute tw-inset-0 tw-m-auto tw-h-[1.5rem] tw-w-[1.5rem]"
              />
            </button>
          </div>
        </div>
      </form>
    </BigBar>
  );
};
