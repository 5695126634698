import Link from 'next/link';

export type BreadcrumbItemProps = {
  label: string;
  href?: string;
};

export const Breadcrumbs: React.FC<{
  list: BreadcrumbItemProps[];
}> = ({ list }) => (
  <div className="tw-custom-side-space tw-max-w-[45rem]">
    <div className="tw-flex tw-flex-wrap tw-gap-y-[0.25rem]">
      {list.map(({ label, href }, index) => (
        <div key={label} className="tw-min-w-0">
          {href ? (
            <Link
              href={href}
              passHref
              className="tw-relative tw-flex tw-items-center"
            >
              {index > 0 && (
                <div className="tw-relative tw-h-[1rem] tw-w-[1rem]">
                  <svg
                    className="tw-absolute tw-inset-0  tw-m-auto tw-h-[0.5rem] tw-w-[0.5rem] tw-text-main-40 rtl:tw-rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                </div>
              )}
              <div className="tw-truncate tw-text-base2 tw-text-main-40">
                {label}
              </div>
            </Link>
          ) : (
            <div className="tw-text-base2 tw-text-main-40">{label}</div>
          )}
        </div>
      ))}
    </div>
  </div>
);
